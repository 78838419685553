export const SET_CURRENT_EMAIL = 'custom//SET_CURRENT_EMAIL'

export function setCurrentEmail(data) {
  return {
    type: SET_CURRENT_EMAIL,
    payload: data,
  }
}

export const SET_CHECKBOX_DATA = 'custom//SET_CHECKBOX_DATA'

export function setCheckboxData(data) {
  return {
    type: SET_CHECKBOX_DATA,
    payload: data,
  }
}

export function getCheckboxData(store) {
  return store.custom.data.checkboxData
}

export const SET_FIRST_TRACKED = 'custom//SET_FIRST_TRACKED'

export function setFirstTracked(data) {
  return {
    type: SET_FIRST_TRACKED,
    payload: data,
  }
}

export function getCurrentEmail(store) {
  return store.custom.data.currentEmail
}

export function getFirstTracked(store) {
  return store.custom.data.firstTracked
}

const initialState = {
  data: {
    firstTracked: false,
    currentEmail: '',
    checkboxData: null,
  },
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_EMAIL:
      return {
        ...state,
        data: {
          ...state.data,
          currentEmail: action.payload,
        },
      }

    case SET_FIRST_TRACKED:
      return {
        ...state,
        data: {
          ...state.data,
          firstTracked: action.payload,
        },
      }

    case SET_CHECKBOX_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          checkboxData: action.payload,
        },
      }

    default:
      return state
  }
}
