import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {},
    lng: 'en',
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
    },
  })

export default brandName => {
  return new Promise(res => {
    const namespaces = [
      'cookie',
      'validation',
      'form',
      'app',
      'error',
      'data',
      'summary',
      'contact',
      'completion',
      'link-expired',
      'browserDeprecationNotification',
      'authenticate',
      'upload',
    ]
    Promise.all(
      namespaces.map(item => {
        return import(`../../resources/base/content/${item}.json`)
          .then(module => {
            i18n.addResourceBundle('en', item, module.default, true, true)
          })
          .then(() => {
            /**
             * Request brand-specific resources and override (using deep) the
             * resources for specific brand. Make sure to chain those to avoid
             * race conditions.
             */
            import(`../../resources/${brandName}/content/${item}.json`)
              .then(module => {
                i18n.addResourceBundle('en', item, module.default, true, true)
              })
              .catch(() => {})
          })
      })
    ).then(res)
  })
}
