import { createStore, combineReducers } from 'redux'

import formReducer from './features/form.redux'
import dataModelsReducer from './features/data-models.redux'
import storefrontReducer from './features/storefront.redux'
import transactionReducer from './features/transaction.redux'
import dealersReducer from './features/dealers.redux'
import sessionReducer from './features/session.redux'
import customReducer from './features/custom.redux'

/* eslint-disable no-underscore-dangle */
const store = createStore(
  combineReducers({
    form: formReducer,
    dataModel: dataModelsReducer,
    storefront: storefrontReducer,
    transaction: transactionReducer,
    dealers: dealersReducer,
    session: sessionReducer,
    custom: customReducer,
  }),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)
/* eslint-enable */

export { store }
