export const FETCH_TRANSACTION_PENDING =
  'transaction//FETCH_TRANSACTION_PENDING'
export const FETCH_TRANSACTION_DONE = 'transaction//FETCH_TRANSACTION_DONE'
export const FETCH_TRANSACTION_ERROR = 'transaction//FETCH_TRANSACTION_ERROR'

export function pending() {
  return {
    type: FETCH_TRANSACTION_PENDING,
    payload: {},
  }
}

export function done(data) {
  return {
    type: FETCH_TRANSACTION_DONE,
    payload: {
      data,
    },
  }
}

export function error(err) {
  return {
    type: FETCH_TRANSACTION_ERROR,
    payload: {
      err,
    },
  }
}

export function getTransactionData(store) {
  return store.transaction.data
}

const initialState = {
  pending: false,
  data: {},
  error: null,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_TRANSACTION_PENDING:
      return {
        ...state,
        data: {},
        pending: true,
        error: null,
      }

    case FETCH_TRANSACTION_DONE:
      return {
        ...state,
        data: action.payload.data,
        pending: false,
        error: null,
      }
    case FETCH_TRANSACTION_ERROR:
      return {
        ...state,
        data: {},
        pending: false,
        error: true,
      }

    default:
      return state
  }
}
