/* eslint-disable  */
import { get, isEmpty } from 'lodash'
import { Analytics } from './index'

export function pushStorefrontDataToDatalayer(storefrontData) {
  let product
  switch (get(storefrontData, 'transaction.product').toUpperCase()) {
    case 'AUTOCREDIT':
      product = 'AutoCredit'
      break
    case 'RENTING':
      product = 'OperateLease'
      break
    default:
      product = 'Finance and lease'
  }

  Analytics.addToDatalayer({
    'product[0].name': product,
    'product[0].category':
      get(storefrontData, 'transaction.product').toUpperCase() === 'AUTOCREDIT'
        ? 'Financing'
        : 'Leasing',
    'product[0].attributes.balloonPayment': get(
      storefrontData,
      'financialProduct.calculation.balloonPayment'
    ),
    'product[0].attributes.netLoanAmount': get(
      storefrontData,
      'financialProduct.calculation.netLoanAmount'
    ),
    'product[0].attributes.grossLoanAmount': get(
      storefrontData,
      'financialProduct.calculation.grossLoanAmount'
    ),
    'product[0].attributes.recurringPayment': get(
      storefrontData,
      'financialProduct.calculation.monthlyLeasingAmount'
    ),
    'product[0].attributes.duration': get(
      storefrontData,
      'financialProduct.calculation.duration'
    ),
    'product[0].attributes.yearlyMileage': get(
      storefrontData,
      'financialProduct.calculation.yearlyMileage'
    ),
    'product[0].attributes.downPaymentAmount': get(
      storefrontData,
      'financialProduct.calculation.downPaymentAmount'
    ),
    'product[0].attributes.effectiveInterestRate': get(
      storefrontData,
      'financialProduct.calculation.effectiveInterestRate'
    ),
    'product[0].attributes.currency': 'EUR',
    'product[0].attributes.paymentFrequency': get(
      storefrontData,
      'financialProduct.calculation.paymentFrequency'
    ),
    'product[0].attributes.nominalInterestRate': get(
      storefrontData,
      'financialProduct.calculation.nominalInterestRate'
    ),
    'product[0].vehicleModel[0].manufacturer': get(
      storefrontData,
      'vehicleData.model.brand'
    ),
    'product[0].vehicleModel[0].name': get(
      storefrontData,
      'vehicleData.model.name'
    ),
    'product[0].vehicleModel[0].name': get(
      storefrontData,
      'vehicleData.model.name'
    ),
    'product[0].vehicleModel[0].basePrice_localCurrency': get(
      storefrontData,
      'vehicleData.model.priceVehicleOnly'
    ),
    'product[0].vehicleModel[0].endPrice_localCurrency': get(
      storefrontData,
      'vehicleData.purchasePriceAmount'
    ),
    'product[0].vehicleModel[0].manufacturer': get(
      storefrontData,
      'vehicleData.model.brand'
    ),
    'product[0].vehicleModel[0].engine.fuelType': get(
      storefrontData,
      'vehicleData.model.engine.fuelType'
    ),
  })
}
