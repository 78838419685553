export const getBrandName = () => {
  return process.env.REACT_APP_WEBSITE_BRAND
}

export const formatBrand = () => {
  let brand = getBrandName()
  switch (brand) {
    case 'vw':
      brand = 'Volkswagen'
      break
    case 'audi':
      brand = 'Audi'
      break
    case 'seat':
      brand = 'SEAT'
      break
    case 'skoda':
      brand = 'Škoda'
      break
    default:
      brand = 'Volkswagen'
  }
  return brand
}

export const BRAND_NAME = process.env.REACT_APP_WEBSITE_BRAND
export const CONNECTION_TIMEOUT = 30000

export const ANALYTICS_DELAY = 4000

export const FILE_UPLOAD_STATUS = {
  SUCCESS: 'SUCCESS',
  LOADING: 'LOADING',
  UNSUPPORTED: 'UNSUPPORTED',
  TOO_BIG: 'TOO_BIG',
  ERROR: 'ERROR',
}
export const MAX_FILESIZE = 1000 * 1000 * 4

export const DATE_FORMAT = 'DD/MM/YYYY'
export const DATE_SEPARATOR = DATE_FORMAT.split('').find(item => {
  return !item.match(/^[a-zA-Z]*$/)
})
export const NUMBER_FORMAT = 'pt'
export const CURRENCY = 'EUR'
export const CURRENCY_SYMBOL = '€'
export const DECIMAL_SEPARATOR = ','
export const THOUSANDS_SEPARATOR = '.'

export const PHONE_COUNTRYCODE = '+351'
export const bronsonConfig = {
  audi: {
    theme: 'audi',
    version: '8.3.0',
  },
  vw: {
    theme: 'vw6',
    version: '8.3.0',
  },
  vwcv: {
    theme: 'vw6',
    version: '8.3.0',
  },
  seat: {
    theme: 'seat',
    version: '8.3.0',
  },
  skoda: {
    theme: 'skoda',
    version: '8.3.0',
  },
  vw6: {
    theme: 'vw6',
    version: '8.3.0',
  },
  vwfs: {
    theme: 'bluelabel',
    version: '8.3.0',
  },
}

export const STEP = {
  ACTIVESC: 'activeSCDetails',
  PERSONAL: 'personalDetails',
  EMPLOYMENT: 'employmentDetails',
  INCOME: 'incomeAndExpensesDetails',
  DEALER: 'dealerDetails',
  DATAPROTECTION: 'dataProtection',
}
